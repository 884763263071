
import Vue, { Component } from 'vue';

import QuizQuestionHeader from '@/components/quiz/QuizQuestionHeader.vue';
import QuizQuestionPoly from '@/components/quiz/poly/QuizQuestionPoly.vue';
import QuizQuestionHash from '@/components/quiz/hash/QuizQuestionHash.vue';
import QuizQuestionOpen from '@/components/quiz/open/QuizQuestionOpen.vue';
import QuizQuestionOrder from '@/components/quiz/order/QuizQuestionOrder.vue';
import QuizQuestionUnknown from '@/components/quiz/QuizQuestionUnknown.vue';
import QuizPagination from '@/components/quiz/QuizPagination.vue';
import CountdownPlate from '@/components/ui/CountdownPlate';
import {
  IQuiz,
  IQuizCurrentAnswer,
  IQuizCurrentAnswerResult,
  IQuizCurrentQuestion,
  IQuizQuestion,
  Quiz,
  QuizAnswerStatus,
  QuizQuestionType,
  toQuizQuestion,
  toQuizResult,
  toQuizWrapper,
} from '@/domains/quiz';
import * as LxpQuizTestService from '@/services/api/lxp-quiz-test';
import { DateTimeISO8601, PlayerSessionStatus } from '@/domains/common';
import { toString } from '@/helpers';
import { Banner } from '@/types/banner';
import DialogComponent from '@/components/ui/DialogComponent';
import { getRemainingTime } from '@/helpers/time';

// TODO: написать набор хелперов для работы с разными типами вопросов и ответов
type IAnswer = Record<string, any>;

interface IQuizQuestionData {
  quiz: IQuiz;
  isLoading: boolean;
  startedAt: DateTimeISO8601;
  questions: IQuizQuestion[];
  firstActiveAnswer: IQuizCurrentAnswer;
  firstActiveQuestion?: IQuizCurrentQuestion;
  /**
   * Убрать answer из даты и оперировать firstActiveAnswer
   */
  answer: IAnswer;
  isAnswered: boolean;
  isChoicesDisabled: boolean;
}

export default Vue.extend({
  name: 'QuizQuestion',

  components: {
    QuizQuestionHeader,
    QuizPagination,
    CountdownPlate,
    QuizQuestionPoly,
    QuizQuestionHash,
    QuizQuestionOpen,
    DialogComponent,
  },

  data(): IQuizQuestionData {
    return {
      isLoading: true,
      quiz: new Quiz(),
      startedAt: '',
      questions: [],
      firstActiveAnswer: {
        id: 0,
        result: [],
      },
      firstActiveQuestion: undefined,
      answer: {},
      isAnswered: false,
      isChoicesDisabled: false,
    };
  },

  computed: {
    playerSessionId(): number {
      return Number(this.$route.params.playerSessionId);
    },

    questionId(): number | undefined {
      return this.$route.params.questionId ? Number(this.$route.params.questionId) : undefined;
    },

    currentQuestionIndex(): number {
      return this.questions.findIndex(this.currentQuestionFinder);
    },

    stepId(): string {
      return this.$route.params.stepId;
    },

    prevQuestionId(): number {
      const question = this.questions[this.currentQuestionIndex - 1];
      return question?.id;
    },

    nextQuestionId(): number | undefined {
      const question = this.questions[this.currentQuestionIndex + 1];
      return question?.id;
    },

    questionComponent(): Component {
      switch (this.firstActiveQuestion?.type) {
        case QuizQuestionType.HASH:
          return QuizQuestionHash;
        case QuizQuestionType.OPEN:
          return QuizQuestionOpen;
        case QuizQuestionType.ORDER:
          return QuizQuestionOrder;
        case QuizQuestionType.POLY:
          return QuizQuestionPoly;
        default:
          return QuizQuestionUnknown;
      }
    },

    questionsCount(): number {
      return this.questions.length;
    },

    currentQuestion(): IQuizQuestion | undefined {
      return this.questions.find(this.currentQuestionFinder);
    },

    isPrevQuestionExist(): boolean {
      return this.currentQuestionIndex > 0;
    },

    isNextQuestionExist(): boolean {
      return this.currentQuestionIndex < this.questionsCount - 1;
    },

    isTimeUnlimited(): boolean {
      return this.quiz.testTimeout === 0;
    },

    isPrevQuestionButtonVisible(): boolean {
      return this.quiz.freeFlow;
    },

    isPrevQuestionButtonDisabled(): boolean {
      if (this.quiz.freeFlow) {
        return !this.isPrevQuestionExist;
      }

      return false;
    },

    isNextQuestionButtonVisible(): boolean {
      return true;
    },

    isNextQuestionButtonDisabled(): boolean {
      return this.quiz.freeFlow && this.isLastQuestion;
    },

    isQuizFinished(): boolean {
      return this.quiz.playerSessionStatus === PlayerSessionStatus.COMPLETED;
    },

    isQuizAnswered(): boolean {
      return (
        this.questions
          .filter((question) => !question.current)
          .every(
            (question) => question.answerStatus === QuizAnswerStatus.COMPLETED
              || question.answerStatus === QuizAnswerStatus.SKIPPED,
          ) && this.isAnswered
      );
    },

    showCloseAlert(): boolean {
      return this.quiz.testTimeout > 0 && !this.checkifTimeIsOver();
    },

    showSkipAlert(): boolean {
      return !this.quiz.freeFlow && !this.isAnswered;
    },

    isLastQuestion(): boolean {
      return !this.isNextQuestionExist;
    },
  },

  watch: {
    '$route.params.questionId': {
      handler() {
        this.prepare();
        this.answer = {};
        this.isAnswered = false;
      },
      immediate: true,
    },
  },

  methods: {
    async prepare() {
      this.isLoading = true;

      await Promise.all([
        this.fetchQuiz(),
        this.questionId ? this.fetchCurrentQuestion() : this.fetchSession(),
      ]).finally(() => {
        this.isLoading = false;
      });
    },

    async previousQuestion() {
      if (this.isAnswered) {
        await this.finishQuestion();
      }

      await this.$router.push(toQuizQuestion({ questionId: toString(this.prevQuestionId) }));
    },

    async nextQuestion() {
      if (this.isAnswered) {
        await this.finishQuestion();
      } else if (!this.quiz.freeFlow) {
        await this.skipQuestion();
      }

      if (this.isNextQuestionExist) {
        await this.$router.push(toQuizQuestion({ questionId: toString(this.nextQuestionId) }));
      } else {
        this.onFinishQuizHandler();
        await this.$router.push(toQuizResult({ questionId: toString(this.questionId), stepId: this.stepId }));
      }
    },

    /**
     * Обновление ответа
     * */
    async updateQuizAnswer(answer: IQuizCurrentAnswerResult) {
      this.answer = answer;

      await this.saveAnswer(answer);
    },

    /**
     * Обработчик события нажатия на кнопку в таймере
     * */
    async timerButtonHandler() {
      await this.onFinishQuizHandler();
    },

    async onSetQuestionHandler(questionId: number) {
      if (this.isAnswered) {
        await this.finishQuestion();
      } else if (!this.quiz.freeFlow) {
        await this.skipQuestion();
      }

      if (this.quiz.freeFlow) {
        await this.$router.push(toQuizQuestion({ questionId: toString(questionId) }));
      }
    },

    async fetchQuiz() {
      const { playerSessionId } = this;

      const params = {
        playerSessionId,
      };

      try {
        const quiz = await LxpQuizTestService.testInfoGet(params);

        this.quiz = new Quiz(quiz);
      } catch (e: any) {
        this.$di.notify.error({ content: e.message });
      }
    },

    async fetchSession() {
      const { playerSessionId } = this;

      const params = { playerSessionId };

      try {
        const response = await LxpQuizTestService.testSessionQuestionsGet({ params });

        if (response.playerSessionStatus === PlayerSessionStatus.COMPLETED) {
          await this.$router.push(toQuizResult({ questionId: toString(this.questionId), stepId: this.stepId }));
          return;
        }

        this.questions = response.questions ?? [];
        this.firstActiveAnswer = response.firstActiveAnswer;
        this.firstActiveQuestion = response.firstActiveQuestion;
        this.startedAt = response.startedAt;
      } catch (e: any) {
        // TODO: handle 404 error. Go to QuizIntro page `./view`
        this.$di.notify.error({
          content: e.message,
        });
      }
    },

    async fetchCurrentQuestion() {
      const params = {
        playerSessionId: this.playerSessionId,
        questionId: this.questionId!,
      };

      try {
        const response = await LxpQuizTestService.testSessionQuestionGet({ params });

        if (response.playerSessionStatus === PlayerSessionStatus.COMPLETED) {
          await this.$router.push(toQuizResult({ questionId: toString(this.questionId), stepId: this.stepId }));
          return;
        }

        this.questions = response.questions ?? [];
        this.firstActiveAnswer = response.currentAnswer;
        this.firstActiveQuestion = response.currentQuestion;
        this.startedAt = response.startedAt;
      } catch (e: any) {
        // TODO: handle 404 error. Go to QuizIntro page `./view`
        this.$di.notify.error({
          content: e.message,
        });
      }
    },

    async saveAnswer(answer: IAnswer) {
      const params = { answerId: this.firstActiveAnswer.id, playerSessionId: this.playerSessionId };
      const payload = { result: answer };

      try {
        await LxpQuizTestService.testSessionAnswerUpdate({ params, payload });
      } catch (e: any) {
        switch (e.response.status) {
          case 422:
            this.$di.notify.error({
              content: e.response.data.error.message,
            });

            await this.$router.push(toQuizResult({ questionId: toString(this.questionId), stepId: this.stepId }));
            break;
          case 409:
            await this.$router.push(toQuizResult({ questionId: toString(this.questionId), stepId: this.stepId }));
            break;
          case 404:
            await this.$router.push(toQuizWrapper({ questionId: toString(this.questionId), stepId: this.stepId }));
            break;
          default:
            this.$di.notify.error({
              content: e.message,
            });
            break;
        }
      }
    },

    async skipQuestion() {
      const params = { answerId: this.firstActiveAnswer.id, playerSessionId: this.playerSessionId };

      try {
        await LxpQuizTestService.testSessionAnswerSkip({ params });
      } catch (e: any) {
        switch (e.response.status) {
          case 422:
            await this.$router.push(toQuizResult({ questionId: toString(this.questionId), stepId: this.stepId }));
            break;
          case 409:
            await this.$router.push(toQuizResult({ questionId: toString(this.questionId), stepId: this.stepId }));
            break;
          case 404:
            await this.$router.push(toQuizWrapper({ questionId: toString(this.questionId), stepId: this.stepId }));
            break;
          default:
            this.$di.notify.error({
              content: e.message,
            });
            break;
        }
      }
    },

    async finishQuestion() {
      const params = { answerId: this.firstActiveAnswer.id, playerSessionId: this.playerSessionId };

      try {
        await LxpQuizTestService.testSessionAnswerFinish({ params });
      } catch (e: any) {
        switch (e.response.status) {
          case 422:
            await this.$router.push(toQuizResult({ questionId: toString(this.questionId), stepId: this.stepId }));
            break;
          case 409:
            await this.$router.push(toQuizResult({ questionId: toString(this.questionId), stepId: this.stepId }));
            break;
          case 404:
            await this.$router.push(toQuizWrapper({ questionId: toString(this.questionId), stepId: this.stepId }));
            break;
          default:
            this.$di.notify.error({
              content: e.message,
            });
            break;
        }
      }
      return true;
    },

    async completeQuiz() {
      const params = { playerSessionId: this.playerSessionId };

      try {
        await LxpQuizTestService.testSessionFinish({ params });
      } catch (e: any) {
        switch (e.response.status) {
          // Прохождение теста уже закончено
          case 409:
            await this.$router.push(toQuizResult({ questionId: toString(this.questionId), stepId: this.stepId }));
            break;
          case 404:
            this.$di.notify.error({
              content: 'Не могу найти никаких данных о прохождении',
            });
            break;
          default:
            this.$di.notify.error({
              content: e.message,
            });
            break;
        }
      }
    },

    currentQuestionFinder(question: IQuizQuestion): boolean {
      return question.id === this.questionId || question.current === true;
    },

    onAnsweredHandler(value: boolean) {
      this.isAnswered = value;
    },

    async onNextHandler() {
      if (this.checkifTimeIsOver()) {
        await this.onFinishQuizHandler();
        return;
      }

      if (this.showSkipAlert) {
        (this.$refs.skipDialog as Banner)
          .open(true)
          .then((continueTest) => {
            if (continueTest) {
              (this.$refs.skipDialog as Banner).close();
            } else {
              this.nextQuestion();
            }
          })
          .catch(() => {
            (this.$refs.skipDialog as Banner).close();
          });
      } else if (this.isLastQuestion) {
        this.onCompleteQuizHandler();
      } else {
        this.nextQuestion();
      }
    },

    async onCloseHandler() {
      if (this.showCloseAlert) {
        (this.$refs.closeDialog as Banner)
          .open(true)
          .then((continueTest) => {
            if (continueTest) {
              (this.$refs.closeDialog as Banner).close();
            } else {
              this.$router.push(toQuizWrapper({ questionId: toString(this.questionId), stepId: this.stepId }));
            }
          })
          .catch(() => {
            (this.$refs.closeDialog as Banner).close();
          });
      } else {
        this.$router.push(toQuizWrapper({ questionId: toString(this.questionId), stepId: this.stepId }));
      }
    },

    async onFinishQuizHandler() {
      const success = async () => {
        /**
         * Если время не ограничено или ограничено, но не закончилось - завершаем тест
         */
        if (!this.isQuizFinished) {
          await this.completeQuiz();
        }

        await this.$router.push(toQuizResult({ questionId: toString(this.questionId), stepId: this.stepId }));
      };

      if (this.shouldShowFinishAlert()) {
        (this.$refs.finishDialog as Banner)
          .open(true)
          .then((continueTest) => {
            if (continueTest) {
              (this.$refs.finishDialog as Banner).close();
            } else {
              success();
            }
          })
          .catch(() => {
            (this.$refs.finishDialog as Banner).close();
          });
      } else {
        success();
      }
    },

    async onCompleteQuizHandler() {
      const success = async () => {
        /**
         * Если время не ограничено или ограничено, но не закончилось - завершаем тест
         */
        if (!this.isQuizFinished) {
          await this.completeQuiz();
        }

        await this.$router.push(toQuizResult({ questionId: toString(this.questionId), stepId: this.stepId }));
      };

      if (this.shouldShowCompleteDialog()) {
        (this.$refs.completeQuizDialog as Banner)
          .open(true)
          .then((continueTest) => {
            if (continueTest) {
              (this.$refs.completeQuizDialog as Banner).close();
            } else {
              success();
            }
          })
          .catch(() => {
            (this.$refs.completeQuizDialog as Banner).close();
          });
      } else {
        success();
      }
    },

    checkifTimeIsOver() {
      if (this.quiz.testTimeout > 0) {
        return getRemainingTime(this.startedAt, this.quiz.testTimeout * 60) <= 0;
      }

      return false;
    },

    shouldShowFinishAlert(): boolean {
      if (this.checkifTimeIsOver()) {
        return false;
      }

      if (this.isQuizAnswered) {
        return false;
      }

      if (!this.isQuizFinished) {
        return true;
      }

      return false;
    },

    shouldShowCompleteDialog(): boolean {
      if (this.checkifTimeIsOver()) {
        return false;
      }

      // Note: если вопрос последний, то при строгом прохождении не показываем диалог, а при свободном показываем
      if (this.isLastQuestion) {
        return this.quiz.freeFlow;
      }

      if (!this.isQuizFinished) {
        return true;
      }

      return false;
    },

    onFinishTimer() {
      this.isChoicesDisabled = true;
      this.onCompleteQuizHandler();
    },
  },
});
