
import Vue, { PropType } from 'vue';

import QuestionImages from '@/components/ui/QuestionImages';
import { IQuizCurrentQuestionBase } from '@/domains/quiz';

export default Vue.extend({
  name: 'QuizQuestionUnknown',

  components: {
    QuestionImages,
  },
  props: {
    question: {
      type: Object as PropType<IQuizCurrentQuestionBase>,
      required: true,
    },
    isDisabled: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
  },

  computed: {
    hasImages(): boolean {
      return this.question.imageUuids?.length > 0;
    },
  },
});
